import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import ClassListNav from "./ClassListNav"
import PageListNav from "./PageListNav"
import BtnBurger from "./BtnBurger"

const Header = ({ siteTitle, currentPath }) => (

    <header className="l-header">
        <div className="l-header__inner ">
            <div className="container">
                <div className="row d-flex align-items-center u-mb-lg:4">
                    <div className="col-auto">
                        <h1 className="c-brand">
                            <Link to="/">{siteTitle}</Link>
                        </h1>

                    </div>
                    <div className="col-auto ml-auto">
                        <BtnBurger />
                    </div>
                    <div className="col-lg-auto ml-lg-auto u-mt:1 u-mt-md:2 u-mt-lg:0">
                        <div className="row d-lg-block align-items-center">
                            <div className="col-auto">
                                <h2 className="ts:baseline u-color:dark-theme u-mt:0">Enseignement aux <br/> techniques de relaxation</h2>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div className="container">
                <nav className="c-main-nav">
                    <ul className="c-main-nav__list">
                        <li><span>Les cours</span>
                            <ul className="c-main-nav__sublist">
                                <ClassListNav currentPath={currentPath} />
                            </ul>
                        </li>
                        <PageListNav currentPath={currentPath} />
                    </ul>
                </nav>

            </div>
        </div>
    </header>

)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
