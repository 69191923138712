import React from "react"


class BtnBurger extends React.Component {
    constructor(props) {
        super(props);
        this.setNavState = this.setNavState.bind(this);
        this.state = {
            opened: false,
        };
    }

    setNavState(){
        this.setState({
            opened: !this.state.opened,
        });
    }
    render() {
        const btnClass = 'c-btn-burger  d-block d-lg-none'

        if (typeof document !== 'undefined'){
            var body = document.querySelector('body');
            if( this.state.opened ){
                body.classList.add("-is-nav-open");
            } else {
                body.classList.remove("-is-nav-open");
            }

            document.addEventListener("DOMContentLoaded", function() {
                var btnBurger = document.querySelector('btnBurger');
                btnBurger.classList.add('-animated')
            });
        }

        return (
            <button onClick={this.setNavState} className={btnClass} >Menu</button>

        )
    }
}

export default BtnBurger
