import React from "react"
import Mailto from 'react-protected-mailto'

import ClassListNav from "./ClassListNav"
import PageListNav from "./PageListNav"

import AnandLogoIcon from '../images/ananda-logo-icon.svg'



const Footer = ({ siteTitle, collapsed }) => (
    <footer className="l-footer u-color-bg:theme u-color:white u-mt:4">
        <div className="container u-py:4">
            <div className="row d-flex align-items-end ">
                <div className="col-lg-4 u-ta:c u-ta-md:l">
                    <img className="u-icon:80 u-mb:1 mx-auto mx-md-0" src={AnandLogoIcon} alt="Ananda" />
                    <span className="ts:5">Renseignements</span>
                    <p><Mailto email='maryline@techniquesderelaxation.org'/><br/>tel. <Mailto tel='04 73 79 16 10' /></p>
                </div>
                <div className="col-lg-7 ml-auto ">
                    <div className="c-sitemap u-pt:2 u-mt:4  u-mt-lg:0">
                        <div className="row">
                            <div className="col-md-6 ">
                                <ul className="c-sitemap__list">
                                    <li><span>Les cours</span>
                                        <ul className="c-sitemap__sublist">
                                            <ClassListNav />
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul className="c-sitemap__list">
                                    <PageListNav />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div className="col u-py:1 u-ta:c u-color-bg:darken-theme">
            <p className="ts:small u-color:white-50"> © {new Date().getFullYear()} Ananda, Site internet par <a href="http://www.pixoil.com" className="c-link">Pixoil</a></p>
        </div>
    </footer>
)


export default Footer
