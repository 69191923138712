import React from "react"
import { Link } from "gatsby"

const PageListNav = ( {currentPath} ) => {
  return (
    <>
    <li className={ ('/votre-enseignante' === currentPath ? '-current' : '')} ><Link to="/votre-enseignante">Votre enseignante</Link></li>
    </>
  )
}

export default PageListNav
