import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const ClassListNav = ( {currentPath} ) => {
    const classItems = useStaticQuery(
      graphql`
        query {
          allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "ClassTemplate"}}}, sort: {fields: frontmatter___order, order: ASC}) {
              edges {
                node {
                  frontmatter {
                    title
                    path
                  }
                }
              }
          }
        }
      `
    )
    let navItems = classItems.allMarkdownRemark.edges


  return (
    <>
    {navItems.map((item) => {
        const itemData =  item.node.frontmatter;
        return (
            <li key={itemData.title} className={ (itemData.path === currentPath ? '-current' : '')} ><Link to={itemData.path} activeClassName="current">{itemData.title}</Link></li>
        );
    })}


    </>
  )
}

export default ClassListNav
